import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';


class MobileServices extends Component {

    
    listenScrollEvent = e => {
       
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }

    render(){
        return (
            
           
            <div id="mobile-services" className="parallax-containers">
               
                <Container>
                    <Row>
                        <Col>
                         
                            <h1 id="services-title">  OUR SERVICES </h1>
                            <p> &lt;RS&gt; offers an array of services to meet clients’ web and design needs. Our services ranges from fron end and back end design to branding and graphic design. We are also opening our services for app development in the immediate future. </p>
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}  className="services-right-wrapper">
                            <Row>
                                <Col>
                                    <img className="services-right-img" alt="" src="./images/webdesign.png" />
                                    <h5> WEB DESIGN </h5>
                                    <p> We handle web design services that would help clients stand out from the competition. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <img className="services-right-img" alt="" src="./images/branding.png" />
                                    <h5> BRANDING </h5>
                                    <p> We offer branding services for companies in order to help them establish their brand identity. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <img className="services-right-img" alt="" src="./images/internal-system.png" />
                                    <h5> INTERNAL SYSTEM </h5>
                                    <p> We service backend systems to make sure the functionality of our clients’ websites are working perfectly. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <img className="services-right-img" alt="" src="./images/mobile-app.png" />
                                    <h5> MOBILE APP DEVELOPMENT </h5>
                                    <p> We will also be providing mobile app development services for our clients soon. </p>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Container>
            </div>
            
        
        );
    }
}

  
export default MobileServices;
  