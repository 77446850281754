import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';


class Team extends Component {


      listenScrollEvent = e => {
   

        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
    render(){
        return (
         
            
            <div id="mobile-team" className="parallax-containers">
            <Container>
                    <Row>
                        <Col md={12} lg={12} className="mobile-team-left-wrapper">
                            <div className="mobile-team-left">
                                <h3> </h3>
                                <h1 id="mobile-team-title"> THE  TEAM </h1>
                                <p> The team comprises of web developers, graphic designers and animators with years of experience in handling various clients in different fields. The team is also composed of marketing and accounts ready to service clients  on their graphic and web needs. </p>
                            </div>
                            <div className="team-vector"><img alt="" id="nard-vector" className="mb-teamvector1" src="./images/team1.jpg" /></div>
                            <div className="team-vector"><img alt="" id="carb-vector" className="mb-teamvector1" src="./images/team2.jpg" /></div>

                            <div className="team-vector"><img alt="" id="ads-vector" className="mb-teamvector1" src="./images/team3.jpg" /></div>
                            <div className="team-vector"><img alt="" id="uel-vector" className="mb-teamvector1" src="./images/team4.jpg" /></div>
                            <div className="team-vector"><img alt="" id="maks-vector" className="mb-teamvector1" src="./images/team5.jpg" /></div>           
        
                        </Col>
                     
                    </Row>
                </Container>
            </div>
    
    
    
           
        
        );
    }

  }
  
export default Team;
  