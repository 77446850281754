import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

class Contact extends Component {
    listenScrollEvent = e => {
        var contact_div = document.getElementById("contact");
        var intContactStart = (document.getElementById('contact').scrollHeight * 4);
        var intContactEnd = (document.getElementById('contact').scrollHeight * 5);
  
        var wt = document.getElementById("contact-title");

        
        if ((window.scrollY >=  intContactStart) && (window.scrollY < intContactEnd)) {
    
            contact_div.classList.add("active");
            wt.classList.remove('roll-out-right');
            wt.classList.add('slide-in-left');
            
        } else {
            contact_div.classList.remove("active");
            wt.classList.add('roll-out-right');
           
            wt.classList.remove('slide-in-left');
           
        }

        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
      render(){

   
            return (
                
                <div id="contact" className="parallax-container">
                    <Container>
                        <Row> 
                            <Col className="contact-wrapper">
                                <div id="contact-title" className="slide-in-left contact-title">
                                    CONTACT US 
                                </div>
                                <div className="contact-details-wrapper">
                                    <div className="contact-item">
                                        <div className="phone-number">
                                            <img alt="" src="./images/phone.png" />
                                        </div>
                                        0916-420-9022
                                    </div>
                                    <div className="contact-item">
                                        <div className="email-address">
                                            <img alt="" src="./images/mail.png" />
                                        </div>
                                        thersprojects@gmail.com
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



                
            );
      }
}

export default Contact
