import React from 'react';
import { slide as Menu } from 'react-burger-menu';

export default props => {
  return (
    <Menu>
      <a className="menu-item"  href="/">
        HOME
      </a>
      <a className="menu-item" href="#mobile-services">
        SERVICES
      </a>
      <a className="menu-item" href="#mobile-team">
      THE TEAM
      </a>
      <a className="menu-item" href="#mobile-works">
      WORKS
      </a>
      <a className="menu-item" href="#mobile-contact">
      CONTACT US
      </a>
    </Menu>
  );
};