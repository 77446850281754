import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';

class MobileHome extends Component {
    listenScrollEvent = e => {

        /*var arrow_1 = document.getElementById("arrow_1");
        var arrow_2 = document.getElementById("arrow_2");
        var arrow_3 = document.getElementById("arrow_3");
        var arrow_4 = document.getElementById("arrow_4");
        var ft = document.getElementById("website-title");
      
        var intServicesStart = document.getElementById('services').scrollHeight;
       
        if ((window.scrollY >= intServicesStart)) {

          //  arrow_1.classList.add("arrow-slide-out");
            arrow_1.classList.remove("arrow-slide");

            //arrow_2.classList.add("arrow-slide-out");
            arrow_2.classList.remove("arrow-slide-2");

           // arrow_3.classList.add("arrow-slide-out");
            arrow_3.classList.remove("arrow-slide-3");

            //arrow_4.classList.add("arrow-slide-out");
            arrow_4.classList.remove("arrow-slide-4");
        

            ft.classList.add('tracking-in-contract-bck');
        }else{
        
            //arrow_1.classList.remove("arrow-slide-out");
            arrow_1.classList.add("arrow-slide");
            
            //arrow_2.classList.remove("arrow-slide-out");
            arrow_2.classList.add("arrow-slide-2");

            //arrow_3.classList.remove("arrow-slide-out");
            arrow_3.classList.add("arrow-slide-3");

            //arrow_4.classList.remove("arrow-slide-out");
            arrow_4.classList.add("arrow-slide-4");

            ft.classList.remove('tracking-in-contract-bck');
            
        }*/

      
      

       



      }

 
      componentDidMount() {

        window.addEventListener('scroll', this.listenScrollEvent)
      }
    render(){
        
        return (
            <div id="mobile-home" className="parallax-containers">
                <Container>
                    <Row>
                        <Col className="home-left-wrapper">
                            <div className="home-left">
                            
                                <h1 id="website-title" className="tracking-in-contract-bck"> FORWARD <br /> THINKING </h1>
                                
                                
                                <p>  We provide Web development services with different packages for your personal or business website. Our freelance web programmers and artists have an extensive knowledge of art and technology, which allows them to create unique websites that are filled with creativity. </p>
                                
                                <div className="see-more-btn"><a href="#mobile-services"> See More </a></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
  }
  
export default MobileHome;
