import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';


class LatestWorks extends Component {

    render(){
        
        return (
            <div id="latest-works" className="parallax-container">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className="latest-works-wrapper">
                            <div className="works-large-title focus-in-contract">
                                Here are a few of our latest projects to give you a taste of what we do.
                            </div>
                            <div className="works-descriptions">
                                <p>
                                Our company works with clients to create a variety of marketing materials. We've worked on projects ranging from web design to branding materials, to collaterals. Explore some of our previous work below and get in touch if you're interested!
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className="latest-works-wrapper">
                        
                            <div className="dev-tools-wrapper">
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/react300.png" />
                                </div>
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/laravel300.png" />
                                </div>
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/shopify300.png" />
                                </div>
                                
                            </div>
                            <div className="dev-tools-wrapper">
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/drupal300.png" />
                                </div>
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/wp300.png" />
                                </div>
                            </div>
                            <div className="dev-tools-wrapper">
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/psd300.png" />
                                </div>
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/xd300.png" />
                                </div>
                                <div className="dev-tool">
                                    <img className="latest-work-logo" src="./images/ai300.png" />
                                </div>
                                
                            </div>
                           
                        </Col>
       
                    </Row>
                    <Row>
                        <Col className="latest-works-right-wrapper">
                            <div className="latest-works-wrapper-2">
                                <Tabs defaultActiveKey="informative" id="latest-works-tabs" className="mb-3">
             
                                    <Tab tabClassName='works-tab' eventKey="informative" title="Informative Websites">
                                    <div className="latest-works-title"> INFORMATIVES </div>


                                    <div className="ecommerce-wrapper">
                                            <div className="ecommerce">
                                                <a href="https://www.mybusinesscoach.com.ph/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/informative-mbc.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> My Business Coach PH </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    We are passionate in touching as many young professionals and entrepreneurs’ lives, by equipping them with the practical tools, methodologies; trainings and processes, built on a foundation of unwavering principles and proven best practices.
                                                    </p>
                                                </div>
                                            </div>
                               
                                            <div className="ecommerce">
                                                <a href="https://sunbeltbrands.com/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/informative-sunbelt.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> SUNBELT</h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    It started with a dream to make life easier and solve day-to-day problems. Sunbelt Brands was born, a company dedicated to finding cutting-edge solutions and providing great services for our customers.
                                                    </p>
                                                </div>
                                            </div>
                                       
                                        </div>
                                        <div className="ecommerce-wrapper">
                                            <div className="ecommerce">
                                                <a href="https://kxoffroad.com/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/informative-kx.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> KX Offroad </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    KX Extreme Off-Road wheels by Katana. Giving your ride the extreme look with pure masculine form, we offer our KX series wheels. We are dedicated in providing you with the style that will correspond with your extreme lifestyle, we offer you perfect designs for your ride.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="ecommerce">
                                                <a href="http://dolcewheels.com/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/informative-dolce.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> Dolce Wheels </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    Dolce wheels are designed with the SUV enthusiast in mind; engineered to support heavy loads associated with Trucks and Sport Utility Vehicles. We provide a variety of finishes to appease a refined taste of luxury.
                                                    </p>
                                                </div>
                                            </div>
                                          
                                        </div>



                                    </Tab>

                                    <Tab tabClassName='works-tab' eventKey="ecommerce" title="E-Commerce Websites">
                                    <div className="latest-works-title"> E-COMMERCE </div>

                                        <div className="ecommerce-wrapper">
                                            <div className="ecommerce">
                                                <a href="https://www.theeveryday.ph/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/ecommerce-everyday.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> EVERYDAY PH </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                        Our quest is to design everyday essentials for you that will be used to the mostest. Increasing practicality and reducing the urge to buy more of the same things. We want to be your last.
                                                    </p>
                                                </div>
                                            </div>
                               
                                            <div className="ecommerce">
                                                <a href="https://pinkpowerdrill.com/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/ecommerce-pinkpower.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> PINK POWER </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    Pink Power started in 2011 in with a small warehouse outside of Tampa, FL. As the years went on, we've expanded to more than 15 employees and continue to operate our warehouse and office just outside of Tampa, FL. Our passion is to take care of people. 
                                                    </p>
                                                </div>
                                            </div>
                                       
                                        </div>
                                        <div className="ecommerce-wrapper">
                                            <div className="ecommerce">
                                                <a href="https://www.opisina.com.ph/" target="_blank">
                                                 
                                                    <div className="ecommerce-image">
                                                        <img className="latest-work-preview" src="./images/ecommerce-opisina.jpg" />
                                                    </div>
                                                    <div className="ecommerce-title">
                                                        <h3> OPISINA </h3>
                                                    </div>
                                                   
                                                </a>
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    Opisina.com.ph is the easiest and smartest way to shop for your office needs. We’re here to take care of your day-to-day requirements while you’re at the forefront of your business. We have the office supplies, equipment and gadgets to help you manage your workload, so you can focus on more important things ahead.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="ecommerce">
                                                
                                                 
                                                <div className="ecommerce-image">
                                                    <img className="latest-work-preview" src="./images/ecommerce-yours.jpg" />
                                                </div>
                                                <div className="ecommerce-title">
                                                    <h3> PUT YOURS HERE </h3>
                                                </div>
                                                   
                                                
                                                <div className="ecommerce-desc">
                                                    <p>
                                                    You're one step closer to make it real!
                                                    </p>
                                                </div>
                                            </div>
                                            
                                          
                                        </div>
                                    </Tab>

                                   
                                </Tabs>
                            </div>
                         
                        </Col>
       
                    </Row>
                </Container>
            </div>
        );
    }
  }
  
export default LatestWorks;