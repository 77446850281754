import React, { Component }  from 'react';
import Home from './components/Home';
import Services from './components/Services';
import Team from './components/Team';
import Navbar from './components/Navbar';

import InsideNavbar from './pages/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './rs.css';
import './index.css';
import Works from './components/Works';
import Contact from './components/Contact';
import { ParallaxProvider } from 'react-scroll-parallax';
import LatestWorks from './pages/works/LatestWorks';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MobileNavbar from './components/mobile/MobileNavbar';
import MobileHome from './components/mobile/MobileHome';
import MobileServices from './components/mobile/MobileServices';
import MobileTeam from './components/mobile/MobileTeam';
import MobileWorks from './components/mobile/MobileWorks';
import MobileContact from './components/mobile/MobileContact';
import Sidebar from './components/Sidebar';

class App extends Component {

  
  render(){
    if (isMobile) {
      let Component 
      switch(window.location.pathname){
        case "/":
          //document.body.style.overflow='hidden';
          document.body.classList.add('mobile-homepage');
          Component = App
          return (
        
            <ParallaxProvider>
              <Router>
                <MobileNavbar />
                <Sidebar />
                <Switch>
                    <Route path="/" exact />
                </Switch>

                <MobileHome />
                <MobileServices />
                <MobileTeam />
                <MobileWorks />
                <MobileContact />

                
                
             
                
            
              </Router>
            </ParallaxProvider>
        
          );
        break;
        case "/works":
          document.body.classList.add('works-page');
          Component = Works 
          return (
        
            <ParallaxProvider>
              <Router>
                <InsideNavbar />
      
                <Switch>
                    <Route path="/" exact />
                </Switch>
                <LatestWorks />
                 
      
                  <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=fRFF9pRuGLtjzhGZneEE0NYClF8o6CxH73KXxRFuuchJFZ9ICscUFFaxYVtE"></script></span>
            
              </Router>
            </ParallaxProvider>
        
          );
        break;
        
      }
    }else{
      let Component 
    
      switch(window.location.pathname){
        case "/":
          document.body.style.overflow='hidden';
          Component = App
          return (
        
            <ParallaxProvider>
              <Router>
                <Navbar />
      
                <Switch>
                    <Route path="/" exact />
                </Switch>
                
                  <Home />
                  <Services />
                  <Team />
                  <Works />
                  <Contact />
      
                
            
              </Router>
            </ParallaxProvider>
        
          );
        break;
        case "/works":
          document.body.classList.add('works-page');
          Component = Works 
          return (
        
            <ParallaxProvider>
              <Router>
                <InsideNavbar />
      
                <Switch>
                    <Route path="/" exact />
                </Switch>
                <LatestWorks />
                 
      
                  <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=fRFF9pRuGLtjzhGZneEE0NYClF8o6CxH73KXxRFuuchJFZ9ICscUFFaxYVtE"></script></span>
            
              </Router>
            </ParallaxProvider>
        
          );
        break;
        
      }
    }
   
 
  }
}

export default App;
