import React, { Component }  from 'react';
import {Link} from 'react-scroll';

import {Container, Row, Col} from 'react-bootstrap';

class MobileNavbar extends Component {
   
    state = {
        opacity: '1'
      }
    
      listenScrollEvent = e => {
        /*var intServicesStart = parseInt(document.getElementById('services').scrollHeight);
        var intTeamStart = parseInt(document.getElementById('team').scrollHeight) * 2;
        var intWorksStart = parseInt(document.getElementById('works').scrollHeight) * 3;
        var intContactStart = parseInt(document.getElementById('contact').scrollHeight) * 4;
        var intContactEnd = parseInt(document.getElementById('contact').scrollHeight) * 5;
        if ((window.scrollY < intServicesStart)) {
      
            this.setState({opacity: '1'});
    
           
        }
        if ((window.scrollY >= intServicesStart) && (window.scrollY <= intTeamStart)) {
      
            this.setState({opacity: '0'});
            
           
        }
        if ((window.scrollY >= intTeamStart) && (window.scrollY <= intWorksStart)) {

        
            this.setState({opacity: '1'});
           ;
           
        }

        if ((window.scrollY >= intWorksStart) && (window.scrollY < intContactStart)) {

         
          
            this.setState({opacity: '1'});
           
           
        }

        if ((window.scrollY >=  intContactStart) && (window.scrollY < intContactEnd)) {
   
            this.setState({opacity: '0'});
          
           
        }

       */

       



      }

 
      componentDidMount() {

        window.addEventListener('scroll', this.listenScrollEvent)
      }

  
    
    render(){
        return (
            <header id="mobile-header">
                <Container>
                    <Row>
                        <Col>
                            <nav className="nabvar">
                                <div className="logowrap">
                                    <Link to="home" spy={true} smooth={true} duration={500} className="navbar-logo"><img style={{opacity: this.state.opacity}} alt="" src="./images/rs-logo.png"></img></Link>
                                    <Link to="home" spy={true} smooth={true} duration={500} className="navbar-logo-white"><img alt="" src="./images/rs-logo-white.png"></img></Link>
                                </div>
                            </nav>
                        </Col>
                    
                    </Row>
                </Container>
            </header>
        )
    }
}

export default MobileNavbar
