import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

class MobileContact extends Component {
    listenScrollEvent = e => {

        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
      render(){

   
            return (
                
                <div id="mobile-contact" className="parallax-containers">
                    <Container>
                        <Row> 
                            <Col className="contact-wrapper">
                                <div id="mobile-contact-title" className="slide-in-left contact-title">
                                    CONTACT US 
                                </div>
                                <div className="contact-details-wrapper">
                                    <div className="contact-item">
                                        <div className="phone-number">
                                            <img alt="" src="./images/phone.png" />
                                        </div>
                                        0916-420-9022
                                    </div>
                                    <div className="contact-item">
                                        <div className="email-address">
                                            <img alt="" src="./images/mail.png" />
                                        </div>
                                        thersprojects@gmail.com
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



                
            );
      }
}

export default MobileContact
