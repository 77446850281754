import React, { Component }  from 'react';
import {Link} from 'react-scroll';

import {Container, Row, Col} from 'react-bootstrap';

class Navbar extends Component {
   
    state = {
        opacity: '1',
        color: 'black'
      }
    
      listenScrollEvent = e => {
        var intServicesStart = parseInt(document.getElementById('services').scrollHeight);
        var intTeamStart = parseInt(document.getElementById('team').scrollHeight) * 2;
        var intWorksStart = parseInt(document.getElementById('works').scrollHeight) * 3;
        var intContactStart = parseInt(document.getElementById('contact').scrollHeight) * 4;
        var intContactEnd = parseInt(document.getElementById('contact').scrollHeight) * 5;
        if ((window.scrollY < intServicesStart)) {
      
            this.setState({opacity: '1'});
            this.setState({color: 'black'});
           
        }
        if ((window.scrollY >= intServicesStart) && (window.scrollY <= intTeamStart)) {
      
            this.setState({opacity: '0'});
            this.setState({color: 'black'});
           
        }
        if ((window.scrollY >= intTeamStart) && (window.scrollY <= intWorksStart)) {

        
            this.setState({opacity: '1'});
            this.setState({color: 'black'});
           
        }

        if ((window.scrollY >= intWorksStart) && (window.scrollY < intContactStart)) {

         
          
            this.setState({opacity: '1'});
            this.setState({color: 'black'});
           
        }

        if ((window.scrollY >=  intContactStart) && (window.scrollY < intContactEnd)) {
   
            this.setState({opacity: '0'});
            this.setState({color: 'white'});
           
        }

       

       



      }

 
      componentDidMount() {

        window.addEventListener('scroll', this.listenScrollEvent)
      }

  
    
    render(){
        return (
            <header id="header">
                <Container>
                    <Row>
                        <Col>
                            <nav className="nabvar">
                                <div className="logowrap">
                                    <Link to="home" spy={true} smooth={true} duration={500} className="navbar-logo"><img style={{opacity: this.state.opacity}} alt="" src="./images/rs-logo.png"></img></Link>
                                    <Link to="home" spy={true} smooth={true} duration={500} className="navbar-logo-white"><img alt="" src="./images/rs-logo-white.png"></img></Link>
                                </div>
                            </nav>
                        </Col>
                        <Col> 
                            <div id="main-menu-wrapper">
                                <ul id="main-menu">
                                    <li className="nav-item">
                                    <Link to="services" style={{color: this.state.color}} className="btn-scroll nav-links" > 
                                        SERVICES
                                    </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link activeClass="active" to="team" style={{color: this.state.color}} spy={true} smooth={true} duration={500} className="nav-links">
                                                THE TEAM
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link activeClass="active" to="works" style={{color: this.state.color}} spy={true} smooth={true} duration={500} className="nav-links" >
                                            WORKS
                                        </Link>
                                    </li>
    
                                    <li className="nav-item last-item">
                                        <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} className="nav-links">
                                                CONTACT US
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

export default Navbar
