import React, { Component }  from 'react';
import {Link} from 'react-scroll';

import {Container, Row, Col} from 'react-bootstrap';

class Navbar extends Component {
   
  
    
    render(){
        return (
            <header id="header">
                <Container>
                    <Row>
                        <Col>
                            <nav className="nabvar">
                                <div className="logowrap">
                                    <a className="navbar-logo"  href="/"><img alt="" src="./images/rs-logo.png"></img></a>
                                    
                                </div>
                            </nav>
                        </Col>
                        <Col> 
                            <div id="main-menu-wrapper">
                                <ul id="main-menu">
                                    <li className="nav-item">
                                        <a className="btn-scroll nav-links"  href="/#services"> SERVICES </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="btn-scroll nav-links"  href="/#team"> TEAM </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="btn-scroll nav-links"  href="/#works"> WORKS </a>
                                    </li>
    
                                    <li className="nav-item last-item">
                                        <a className="btn-scroll nav-links"  href="/#contact"> CONTACT </a>
                                    </li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

export default Navbar
