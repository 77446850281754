import React, {Component} from 'react'
import {Container, Row, Col} from 'react-bootstrap';

class Works extends Component {

    listenScrollEvent = e => {
        var works_div = document.getElementById("works");
        var intWorksStart = document.getElementById('works').scrollHeight * 3;
        var monitor = document.getElementById("monitor");
        var mp = document.getElementById("mobile-phones");
        var wt = document.getElementById("works-title");

        
        if ((window.scrollY >= intWorksStart)) {
    
            works_div.classList.add("active");

            monitor.classList.add('slide-in-left');
            mp.classList.add('slide-in-right');
            wt.classList.add('tracking-in-contract-bck');
        } else {
            works_div.classList.remove("active");
 
            monitor.classList.remove('slide-in-left');
            mp.classList.remove('slide-in-right');
            wt.classList.remove('tracking-in-contract-bck');
        }

        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
    render (){
        return (
        
            <div id="works" className="parallax-container">
                <Container>
                    <Row> 
                        <Col md={4} lg={4} className="works-left-wrapper">
                            <div className="works-left">
                                <h3> OUR </h3>
                                <h1 id="works-title" className="tracking-in-contract-bck"> WORKS </h1>
                                <p> Our project portfolio spans from web design to branding materials to marketing collaterals. Check out some of our previous projects that we worked on with our clients. </p>
                                <div className="white-btn button-wrapper">
                                    <a href="/works" className="button-rs"> LEARN MORE </a>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={8} className="works-right-wrapper">
                            <Row>
                                <Col>
                                    
                                    <img id="monitor" className="works-right-img" alt="" src="./images/monitor.png" />
                                    <img id="mobile-phones" className="works-right-img" alt="" src="./images/mobilephones.png" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
    
    
    
            
        )
    }
  
}

export default Works
