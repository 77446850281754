import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';


class Team extends Component {


      listenScrollEvent = e => {
        var team_div = document.getElementById("team");
        var intTeamStart = document.getElementById('team').scrollHeight * 2;
        var nard = document.getElementById("nard-vector");
        var carb = document.getElementById("carb-vector");
        var ads = document.getElementById("ads-vector");
        var uel = document.getElementById("uel-vector");
        var maks = document.getElementById("maks-vector");
        var tt = document.getElementById("team-title");

        
        if ((window.scrollY >= intTeamStart)) {
    
            team_div.classList.add("active");
            nard.classList.add("swing-in-top-fwd");
            carb.classList.add("swing-in-left-fwd");
            ads.classList.add("swing-in-top-bck");
            uel.classList.add("swing-in-right-fwd");
            maks.classList.add("swing-in-bottom-fwd");
            tt.classList.add('tracking-in-contract-bck');
        } else {
            team_div.classList.remove("active");
            nard.classList.remove("swing-in-top-fwd");
            carb.classList.remove("swing-in-left-fwd");
            ads.classList.remove("swing-in-top-bck");
            uel.classList.remove("swing-in-right-fwd"); 
            maks.classList.remove("swing-in-bottom-fwd"); 
            tt.classList.remove('tracking-in-contract-bck');
        }

        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
    render(){
        return (
         
            
            <div id="team" className="parallax-container">
            <Container>
                    <Row>
                        <Col md={4} lg={4} className="team-left-wrapper">
                            <div className="team-left">
                                <h3> THE </h3>
                                <h1 id="team-title"> TEAM </h1>
                                <p> The team comprises of web developers, graphic designers and animators with years of experience in handling various clients in different fields. The team is also composed of marketing and accounts ready to service clients  on their graphic and web needs. </p>
                            </div>
                        </Col>
                        <Col md={8} lg={8}  className="team-right-wrapper">
                            <div className="team-rights">
                                <div className="team-rights-col">
                                    <div className="team-vector"><img alt="" id="nard-vector" className="teamvector1" src="./images/team1.jpg" /></div>
                                    <div className="team-vector"><img alt="" id="carb-vector" className="teamvector1" src="./images/team2.jpg" /></div>
    
                                        
                                </div>   
                                <div className="team-rights-col">

                                    <div className="team-vector"><img alt="" id="ads-vector" className="teamvector1" src="./images/team3.jpg" /></div>
                                    <div className="team-vector"><img alt="" id="uel-vector" className="teamvector1" src="./images/team4.jpg" /></div>
                                    <div className="team-vector"><img alt="" id="maks-vector" className="teamvector1" src="./images/team5.jpg" /></div>           
        
                                </div>
                                
                            
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
    
    
    
           
        
        );
    }

  }
  
export default Team;
  