import React, {Component} from 'react'
import {Container, Row, Col} from 'react-bootstrap';

class MobileWorks extends Component {

    listenScrollEvent = e => {
    
        
    }

 
    componentDidMount() {

    window.addEventListener('scroll', this.listenScrollEvent)
    }
    render (){
        return (
        
            <div id="mobile-works" className="parallax-containers">
                <Container>
                    <Row> 
                        <Col md={4} lg={4} className="mobile-works-left-wrapper">
                            <div className="mobile-works-left">
            
                                <h1 id="mobile-works-title"> OUR WORKS </h1>
                                <p> Our project portfolio spans from web design to branding materials to marketing collaterals. Check out some of our previous projects that we worked on with our clients. </p>
                                <img id="mobile-monitor" className="mobile-works-right-img" alt="" src="./images/monitor.png" />
                                    <img id="mobile-mobile-phones" className="mobile-works-right-img" alt="" src="./images/mobilephones.png" />
                                <div className="white-btn button-wrapper">
                                    <a href="/works" className="button-rs"> LEARN MORE </a>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={8} className="mobile-works-right-wrapper">
                            <Row>
                                <Col>
                                    
                   
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
    
    
    
            
        )
    }
  
}

export default MobileWorks
